@tailwind base;
@tailwind components;
@tailwind utilities;

.form-field {
    @apply p-4 outline-transparent outline-offset-2 focus:outline-herkul-500 focus:outline-offset-4 block w-full rounded placeholder-slate-500 transition-all dark:bg-gray-900 dark:text-white dark:placeholder:text-gray-400 bg-neutral-100;
}

.btn {
    @apply p-4 bg-herkul-500 flex w-full rounded justify-center items-center text-white font-bold active:outline-herkul-500 active:outline-offset-4;
}
