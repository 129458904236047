@font-face {
    font-family: 'ShaikhHamdullahMushaf';
    src: local('ShaikhHamdullahMushaf'),
    url('../public/assets/fonts/ShaikhHamdullahMushaf.ttf') format('truetype');
    font-weight: bold;
}

.arabic-text {
    font-family: 'ShaikhHamdullahMushaf', 'Roboto', ui-sans-serif;
    line-height: 54px;
}
